<template>
  <v-row no-gutters justify="center">
    <v-col
      v-for="(resource, index) in value"
      :key="`resource-${index}`"
      cols="auto"
      class="pt-3 pb-2 px-1"
    >
      <v-row
        :class="{ 'kpi-card-highlighted': resource.isCritical }"
        class="kpi-card"
        no-gutters
        align="center"
      >
        <v-col cols="auto" class="pr-2">
          <v-card
            :color="$options.colors[resource.color]"
            class="lighten-1"
            style="height: 25px; width: 25px"
            flat
          />
        </v-col>
        <v-col>
          <span class="text-h6" v-text="resource.name" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ResourceListRow',
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  colors: {
    red: 'red',
    green: 'green',
    yellow: 'amber',
    blue: 'indigo',
  },
}
</script>

<style lang="scss" scoped>
  .kpi-card {
    margin: 3px;
    padding: 1px;
  }

  .kpi-card-highlighted {
    margin: 0px;
    border: 3px dashed red;
    border-radius: 5px;
  }
</style>
